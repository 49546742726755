import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common_components/Header';
import Drawer from '../../../common_components/Drawer';
import { fill_action } from '../../../actions/master_action/checklist_action'
import {
  clientEngagement_action,
  deleteClientEngagement_action,
  downloadStatus_action
} from '../../../actions/engagement_action/client_engagement_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../../utils/Loader'
import { engagementStatus, clientEngagementStatusActionId, clearLocalStorage, downloadReport, Client_Engagement } from '../../../utils/constants'
import Pagination from '../../../utils/pagination'
import { getModuleDeleteAccess, getModuleUpdateAccess, getModuleWriteAccess } from '../../../utils/RoleAccess';

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
  ...state
})

class ClientEngagementList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clientEngagementName: '',

      engPartnerName: '',
      qualityReviewerName: '',
      engagementTypeName: '',
      clientEngagementList: [],
      engagementTypeNameList: [],
      eq: '',
      status: '',
      isOpen: false,
      clientEngagementlistDeleteId: '',


      finYear: '',
      clientName: '',
      status: '',
      statusList: [],
      clientEngagementList: [],
      currentPage: 1,
      tablePerPage: 10,
      recordsPerPage: 10,
      pageSizeInput: "10",

      finYearNameCALS: '',
      clientNameCALS: '',
      statusCALS: '',

      isClientNameSort: false,
      isFinancialYearSort: false,
      isEngagementTypeNameSort: false,
      isLocationSort: false,
      isFromDateSort: false,
      isToDateSort: false,
      isStatusSort: false,
      isSearchTab: false,
    }
    this.handleSearch = this.handleSearch.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.getClientEngagementList = this.getClientEngagementList.bind(this)
    this.getFillList = this.getFillList.bind(this)
    this.updateItem = this.updateItem.bind(this);
    this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this);
    this.handlePageSizeInputChange = this.handlePageSizeInputChange.bind(this);
    this.record = this.record.bind(this)
    this.clearValue = this.clearValue.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)

    this.clientNameSort = this.clientNameSort.bind(this)
    this.financialYearSort = this.financialYearSort.bind(this)
    this.engagementTypeNameSort = this.engagementTypeNameSort.bind(this)
    this.locationSort = this.locationSort.bind(this)
    this.fromDateSort = this.fromDateSort.bind(this)
    this.toDateSort = this.toDateSort.bind(this)
    this.statusSort = this.statusSort.bind(this)

  }


  componentDidMount = () => {
    this.setState({
      clientNameCALS: localStorage.getItem('clientNameCALS'),
      finYearNameCALS: localStorage.getItem('finYearNameCALS'),
      statusCALS: localStorage.getItem('statusCALS'),
      clientName: localStorage.getItem('clientNameCALS') != '' ? localStorage.getItem('clientNameCALS') : '',
      finYear: localStorage.getItem('finYearNameCALS') != '' ? localStorage.getItem('finYearNameCALS') : '',
      status: localStorage.getItem('statusCALS') != '' ? localStorage.getItem('statusCALS') : '',
    })
    clearLocalStorage("clientEngagement")
    setTimeout(() => {
      this.getClientEngagementList()
    }, 50);
    this.getFillList(engagementStatus, '')
    // document.addEventListener('keydown', this.handleTabKeyPress);
    // document.addEventListener('click', this.handleInputClick);

  }

  componentWillUnmount() {
    // document.removeEventListener('keydown', this.handleTabKeyPress);
    // document.removeEventListener('click', this.handleInputClick);

  }


  handleTabKeyPress = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
      const focusedElement = document.activeElement;
      const { previouslyFocusedElement } = this.state;

      if (previouslyFocusedElement) {
        previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
      }

      if (focusedElement.classList.contains('form-control') ||
        focusedElement.classList.contains('js-example-basic-single') ||
        focusedElement.classList.contains('example-custom-input')) {
        focusedElement.style.outline = '1px solid grey'; // Apply outline to focused element
        this.setState({ previouslyFocusedElement: focusedElement }); // Update previously focused element
      }
    }
  };


  handleInputClick = (event) => {
    const { previouslyFocusedElement } = this.state;
    const clickedElement = event.target;

    if (previouslyFocusedElement) {
      previouslyFocusedElement.style.outline = ''; // Remove outline from previously focused element
    }

    if (clickedElement.classList.contains('form-control') ||
      clickedElement.classList.contains('js-example-basic-single') ||
      clickedElement.classList.contains('example-custom-input')) {
      clickedElement.style.outline = '1px solid grey'; // Apply outline to clicked element
      this.setState({ previouslyFocusedElement: clickedElement }); // Update previously focused element
    } else {
      this.setState({ previouslyFocusedElement: null }); // Reset if the clicked element is not a target
    }
  };

  //sorting 

  statusSort() {
    const { clientEngagementList, isStatusSort } = this.state
    this.setState({ isStatusSort: !isStatusSort })
    if (!isStatusSort) {
      // sorting
      var statusSort = clientEngagementList.sort((a, b) => {
        const nameA = a.Status; // ignore upper and lowercase
        const nameB = b.Status; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: statusSort })
    }
    else {
      // reverse
      var statusSort = clientEngagementList.sort((a, b) => {
        const nameA = a.Status; // ignore upper and lowercase
        const nameB = b.Status; // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: statusSort })
    }
    this.setState({ isStatusSort: !isStatusSort })
  }

  toDateSort() {
    const { clientEngagementList, isToDateSort } = this.state
    this.setState({ isToDateSort: !isToDateSort })
    if (!isToDateSort) {
      // sorting
      var toDateSort = clientEngagementList.sort((a, b) => {
        const nameA = a.EndDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.EndDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: toDateSort })
    }
    else {
      // reverse
      var toDateSort = clientEngagementList.sort((a, b) => {
        const nameA = a.EndDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.EndDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: toDateSort })
    }
    this.setState({ isToDateSort: !isToDateSort })
  }

  fromDateSort() {
    const { clientEngagementList, isFromDateSort } = this.state
    this.setState({ isFromDateSort: !isFromDateSort })
    if (!isFromDateSort) {
      // sorting
      var fromDateSort = clientEngagementList.sort((a, b) => {
        const nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: fromDateSort })
    }
    else {
      // reverse
      var fromDateSort = clientEngagementList.sort((a, b) => {
        const nameA = a.StartDate.toUpperCase(); // ignore upper and lowercase
        const nameB = b.StartDate.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: fromDateSort })
    }
    this.setState({ isFromDateSort: !isFromDateSort })
  }

  locationSort() {
    const { clientEngagementList, isLocationSort } = this.state
    this.setState({ isLocationSort: !isLocationSort })
    if (!isLocationSort) {
      // sorting
      var locationSort = clientEngagementList.sort((a, b) => {
        const nameA = a.LocationName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.LocationName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: locationSort })
    }
    else {
      // reverse
      var locationSort = clientEngagementList.sort((a, b) => {
        const nameA = a.LocationName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.LocationName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: locationSort })
    }
    this.setState({ isLocationSort: !isLocationSort })
  }

  engagementTypeNameSort() {
    const { clientEngagementList, isEngagementTypeNameSort } = this.state
    this.setState({ isEngagementTypeNameSort: !isEngagementTypeNameSort })
    if (!isEngagementTypeNameSort) {
      // sorting
      var engagementTypeNameSort = clientEngagementList.sort((a, b) => {
        const nameA = a.EngagementType.toUpperCase();  // ignore upper and lowercase
        const nameB = b.EngagementType.toUpperCase();  // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: engagementTypeNameSort })
    }
    else {
      // reverse
      var engagementTypeNameSort = clientEngagementList.sort((a, b) => {
        const nameA = a.EngagementType.toUpperCase(); // ignore upper and lowercase
        const nameB = b.EngagementType.toUpperCase();  // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: engagementTypeNameSort })
    }
    this.setState({ isEngagementTypeNameSort: !isEngagementTypeNameSort })
  }

  financialYearSort() {
    const { clientEngagementList, isFinancialYearSort } = this.state
    this.setState({ isFinancialYearSort: !isFinancialYearSort })
    if (!isFinancialYearSort) {
      // sorting
      var financialYearSort = clientEngagementList.sort((a, b) => {
        const nameA = a.FinancialYear.toUpperCase(); // ignore upper and lowercase
        const nameB = b.FinancialYear.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: financialYearSort })
    }
    else {
      // reverse
      var financialYearSort = clientEngagementList.sort((a, b) => {
        const nameA = a.FinancialYear.toUpperCase(); // ignore upper and lowercase
        const nameB = b.FinancialYear.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: financialYearSort })
    }
    this.setState({ isFinancialYearSort: !isFinancialYearSort })
  }

  clientNameSort() {
    const { clientEngagementList, isClientNameSort } = this.state
    this.setState({ isClientNameSort: !isClientNameSort })
    if (!isClientNameSort) {
      // sorting
      var clientNameSort = clientEngagementList.sort((a, b) => {
        const nameA = a.ClientName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.ClientName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: clientNameSort })
    }
    else {
      // reverse
      var clientNameSort = clientEngagementList.sort((a, b) => {
        const nameA = a.ClientName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.ClientName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.setState({ clientEngagementList: clientNameSort })
    }
    this.setState({ isClientNameSort: !isClientNameSort })
  }

  // get fill List dropdown  
  getFillList(id, reference) {
    this.setState({ isLoading: true })
    this.props
      .fill_action(id, reference)
      .then((data) => {
        if (data.error != 1) {
          if (id === engagementStatus) {
            this.setState({
              statusList: data.data
            })
          }
          this.setState({
            isLoading: false
          })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.data, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }



  // get engagement allocation list
  getClientEngagementList() {
    const { finYear, clientName, status } = this.state
    // get clientEngagement list default
    clearLocalStorage("clientEngagement")
    this.setState({ isLoading: true })
    this.props
      .clientEngagement_action(finYear, clientName, status == '' ? 1 : status)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ clientEngagementList: data.data, isLoading: false })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // search list
  handleSearch() {
    const { finYear, clientName, status } = this.state
    clearLocalStorage("clientEngagement")
    console.log("handle search 1", status);
    this.setState({ isLoading: true })
    this.props
      .clientEngagement_action(finYear, clientName, status == '' ? 1 : status)
      .then((data) => {
        if (data.error != 1) {
          this.setState({ clientEngagementList: data.data, isLoading: false, currentPage: 1, })
        }
        else {
          this.setState({ isLoading: false })
          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
    console.log("handle search 2", status);
    localStorage.setItem("clientNameCALS", clientName)
    localStorage.setItem("finYearNameCALS", finYear)
    localStorage.setItem("statusCALS", status)
    console.log("handle search 3", localStorage.getItem("statusCALS"));
  }

  clearValue() {
    this.setState({
      clientName: '',
      finYear: '',
      status: '',
      clientEngagementList: [],
      currentPage: 1,
      clientNameCALS: '',
      finYearNameCALS: '',
      statusCALS: '',
    })
    clearLocalStorage('')
    setTimeout(() => {
      this.getClientEngagementList()
    }, 300);

  }

  onSearchTabClick() {
    const { isSearchTab, } = this.state;
    console.log("onSearchTabClick", isSearchTab)
    this.setState({ isSearchTab: !isSearchTab })
  }

  // add list 
  handleAdd() {
    this.props.navigate('/clientEngagementAU', { state: { is_add: true, isUpdateChecklistProvision: true } })
  }

  // update list
  handleUpdate(clientEngagementlistId, EngagementHeadID, EngagementpartnerID) {
    const EmployeeId = parseInt(localStorage.getItem("EmployeeId"))

    if (EmployeeId != EngagementHeadID && EmployeeId == EngagementpartnerID) {
      this.props.navigate('/clientEngagementAU', { state: { is_update: true, clientEngagementId: clientEngagementlistId, isUpdateChecklistProvision: true } })
    } else {
      this.props.navigate('/clientEngagementAU', { state: { is_update: true, clientEngagementId: clientEngagementlistId, isUpdateChecklistProvision: false } })
    }
  }

  // delete list
  handleDelete(e, clientEngagementlistId) {
    e.preventDefault()
    this.setState({ isLoading: true })

    // this.props.navigate('/clientEngagementListAU', { state: { is_delete: true, clientEngagementlistId: clientEngagementlistId } })
    var id = clientEngagementlistId
    this.props
      .deleteClientEngagement_action(id)
      .then((data) => {
        if (data.error != 1) {
          toast.success('Data deleted successfully', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
          this.setState({ isOpen: !this.state.isOpen, isLoading: false })
          this.getClientEngagementList()
        }
        else {
          this.setState({ isLoading: false })

          toast.error(data.msg, {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //toggle model for delete.................................
  toggleModal(clientEngagementlistId) {
    const { isOpen, clientEngagementlistDeleteId } = this.state
    this.setState({ isOpen: !isOpen })
    if (clientEngagementlistId == '') {
      return;
    } else {
      this.setState({ clientEngagementlistDeleteId: clientEngagementlistId })
    }

  }
  handleKeyDown(e) {
    if (e.code === 'Enter') {
      this.handleSearch()
    }
  }
  updateItem(item) {
    this.setState({ currentPage: item });
  }

  updateRecordsPerPage = (newSize) => {
    const parsedSize = parseInt(newSize, 10);
    if (!isNaN(parsedSize) && parsedSize > 0) {
      this.setState({ recordsPerPage: parsedSize, currentPage: 1 });
    }
    this.setState({ pageSizeInput: newSize }); // Keep the input value updated
  };

  handlePageSizeInputChange = (event) => {
    const input = event.target.value;
    if (/^\d*$/.test(input)) { // Only allow digits
      this.setState({ pageSizeInput: input });
    }
  };

  // download audit plane...............
  handleDownload(e, id, statusid) {
    e.preventDefault()
    this.setState({ isLoading: true })
    this.props
      .downloadStatus_action(id, statusid)
      .then((data) => {
        if (data != null) {
          this.setState({ isLoading: false })
          var url = `${downloadReport}?SessionIdentifier=${data}&Export=Yes`;
          const win = window.location.replace(url, '_blank');
          if (win != null) {
            win.focus();
          }
        } else {
          this.setState({ isLoading: false })
          toast.error('There is some problem to download report. Please try again later!', {
            theme: 'colored',
            autoClose: 3000,
            hideProgressBar: true,
          })
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false })
      });

  }

  record(clientEngagementList) {
    const EmployeeId = parseInt(localStorage.getItem("EmployeeId"))

    return (
      <table className="table table-bordered tb-action du-table">
        <thead>
          <tr>
            <th style={{ width: '60px' }} />
            <th style={{ textAlign: 'left' }} onClick={this.clientNameSort}>Client</th>
            <th onClick={this.financialYearSort}>Financial Year</th>
            <th onClick={this.engagementTypeNameSort}>Engagement Type</th>
            <th onClick={this.locationSort}>Location</th>
            <th onClick={this.fromDateSort}>From Date</th>
            <th onClick={this.toDateSort}>To Date</th>
            <th style={{ width: '70px' }} onClick={this.statusSort}>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            clientEngagementList.length > 0 ?
              clientEngagementList.map((data, index) => (
                <>
                  <tr>
                    <td className="text-center" style={{ padding: '5px!important' }}>
                      <div className="table__button-group">
                        {getModuleUpdateAccess(Client_Engagement) ?
                          <a>
                            <i className="fa fa-pencil" style={{ color: '#aac722' }} onClick={() => this.handleUpdate(data.EngagementID, data.EngagementHeadID, data.EngagementPartnerID)} />
                          </a>
                          :
                          <a>
                            <i className="fa fa-eye" onClick={() => this.handleUpdate(data.EngagementID, data.EngagementHeadID, data.EngagementPartnerID)} />
                          </a>
                        }
                        {data.Status === 'In-Process' && !data.IsAnyCompleted && getModuleDeleteAccess(Client_Engagement) && EmployeeId != data.EngagementHeadID && EmployeeId == data.EngagementPartnerID ?
                          <a><span className="text-danger"><i className="fa fa-trash-o" onClick={() => this.toggleModal(data.EngagementID)} />
                          </span></a>
                          : <></>}
                      </div>
                    </td>
                    <td style={{ textAlign: 'left' }}>{data.ClientName}</td>
                    <td>{data.FinancialYear}</td>
                    <td>{data.EngagementType}</td>
                    <td style={{ textAlign: 'left' }}>{data.IsLocationBased ? data.LocationName : null}</td>
                    <td>{moment(data.StartDate).format('DD-MM-YYYY')}</td>
                    <td>{moment(data.EndDate).format('DD-MM-YYYY')}</td>
                    <td>{data.Status}</td>
                    <td class="text-center" style={{ padding: '5px!important' }}>
                      {data.EngagementStatusID != '2' ? (
                        <a class={data.EngagementStatusID != '2' ? "no-sac" : "sac"}><i class="fa fa-spinner" title="status of audit report" style={{ color: '#242424' }} onClick={e => this.handleDownload(e, data.EngagementID, data.EngagementStatusID)}></i></a>
                      ) : (null)}
                      {data.EngagementStatusID == '2' ? (
                        <a ><i class="fa fa-check-circle-o" title="summary report of audit closure" onClick={e => this.handleDownload(e, data.EngagementID, data.EngagementStatusID)}></i></a>
                      ) : (null)}

                    </td>
                  </tr></>
              )) : <tr><td colSpan={9} style={{ textAlign: 'center' }}>Record not found</td></tr>
          }

        </tbody>
      </table>
    )
  }

  render() {
    const { isSearchTab, clientEngagementList, tablePerPage, isLoading, clientEngagementlistDeleteId,
      isOpen, status, statusList, clientName, finYear } = this.state
    const { currentPage, recordsPerPage, pageSizeInput } = this.state;

    // const recordsPerPage = 10
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    // Records to be displayed on the current page
    const currentRecords = clientEngagementList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(clientEngagementList.length / recordsPerPage)


    return (
      <>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          null
        )}
        <Header />
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            {/* Left side menu */}
            <Drawer />
            {/* end left menu */}
            {/* start page */}
            <div className="main-panel main-box">
              <div className="content-wrapper">
                <div className="main-breadcum">
                  <ul className="breadcrumb">
                    <li><a href="#">Client Engagement</a></li>
                    <li />
                  </ul>
                </div>
                <div className='page-space-from-bottom'>
                  <div className="row">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                .accordion-button::after {\n                                background-size: 13px;\n                                display: none;\n                            }\n                            " }} />
                    <div className="col-md-12 pg-body grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <div className="accordion accordion-flush " id="accordionFlushExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed list-search" onClick={() => this.onSearchTabClick()} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={isSearchTab ? 'true' : 'false'} aria-controls="flush-collapseOne">
                                  <i className="fa fa-plus " style={{ marginRight: '5px' }} />  Search
                                </button>
                              </h2>
                              {isSearchTab ? <div id="flush-collapseOne" className={isSearchTab ? "collapse show" : "collapsing hide"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">                              <div className="accordion-body" style={{ border: '1px solid #ddd', borderTop: 'none', padding: '5px 10px 7px' }}>
                                <div className="d-flex flex-wrap row">
                                  <div className="form-group col-md-3">
                                    <label>Client </label>
                                    <input type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={clientName}
                                      onChange={(e) => this.setState({ clientName: e.target.value })}
                                      onKeyDown={e => this.handleKeyDown(e)}

                                      required />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label>Financial Year</label>
                                    <input type="text"
                                      className="form-control"
                                      placeholder=''
                                      value={finYear}
                                      onChange={(e) => this.setState({ finYear: e.target.value })}
                                      onKeyDown={e => this.handleKeyDown(e)}
                                      required />
                                  </div>

                                  <div className="form-group col-md-3">
                                    <label>Status</label>
                                    <select className="js-example-basic-single" style={{ width: '100%' }}
                                      value={status} onChange={e => this.setState({ status: e.target.value })}>
                                      <option value=''>Select</option>
                                      {statusList.map(item => (
                                        <option
                                          key={item.EngagementStatusID}
                                          value={item.EngagementStatusID}>
                                          {item.Name}
                                        </option>
                                      ))}

                                    </select>
                                  </div>
                                  <div className="form-group col-md-2  filter-btn-col p-0">
                                    <label></label>
                                    <button type="button" className="btn btn-Add rounded-1 fil-btn py-2" onClick={this.handleSearch}> Search</button>
                                    <button type="button" className="btn btn-clear rounded-1 mx-1 fil-btn py-2 px-3" onClick={this.clearValue}> Clear</button>

                                  </div>
                                </div>
                              </div>
                              </div> : <></>}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 mt-1">
                              {getModuleWriteAccess(Client_Engagement) && <button type="button" className="btn btn-Add rounded-1 px-3 float-right mb-1 py-2" onClick={this.handleAdd}> Add</button>}
                              <div className="table-responsive tbs4 common-table trail-tb" style={{ width: '100%' }}>
                                {this.record(currentRecords)}
                                {clientEngagementList.length > 0 ? <Pagination
                                  nPages={nPages}
                                  currentPage={currentPage}
                                  setCurrentPage={this.updateItem}
                                  recordsPerPage={this.state.pageSizeInput} // Pass the raw input value
                                  setRecordsPerPage={this.updateRecordsPerPage} // Pass the function to update records per page
                                  totalRecords={clientEngagementList.length}
                                /> : <></>}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end page */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
        <ToastContainer />
        {/* <Modal
          isOpen={isOpen}
          onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header">
                <h4 class="modal-title modal_title_text">Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="pop_label">Do you really want to delete this record?</h3>
                  </div>
                </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group text-right  mod-fot">
                      <a href="javascript:void(0);" class="btn save_btn del-pop-save" onClick={(e) => this.handleDelete(e, clientEngagementlistDeleteId)}><i class="fa fa-check"></i>&nbsp; Yes</a>
                      <a href="javascript:void(0);" class="btn cancel_btn del-pop-cancel" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={this.toggleModal}><i class="fa fa-times"></i>&nbsp; No</a>
                    </div>
                  </div>
             
              </div>
            </div>
          </div>
        </Modal> */}

        <Modal isOpen={isOpen} onRequestClose={this.toggleModal}>
          <div className="modal-dialog custom_modal_dialog">
            <div className="modal-content" style={{ borderRadius: '0px', width: '500px' }}>
              <div class="modal-header pt-1 bg-danger"><h4 class="modal-title modal_title_text"><i class="fa fa-trash-o"></i> Confirm Delete</h4>
                <button type="button " class="close pop-close" data-dismiss="modal" onClick={this.toggleModal} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12"><h3 class="pop_label text-danger pt-3 pb-1">Do you really want to delete this record?</h3></div>
                </div>
              </div>
              <div class="modal-footer p-1">
                <div class="form-group text-right">
                  <a href="javascript:void(0);" class="btn btn btn-outline-danger del-pop-btn mx-1" onClick={(e) => this.handleDelete(e, clientEngagementlistDeleteId)}> Yes</a>
                  <a href="javascript:void(0);" class="btn btn btn-outline-secondary del-pop-btn del-pop-close-btn mx-1" data-dismiss="modal" onClick={this.toggleModal}>No</a>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(connect(null, {
  clientEngagement_action,
  deleteClientEngagement_action,
  downloadStatus_action,
  fill_action
})(ClientEngagementList));